<template>
    <b-card no-body class="p-1">
        <b-overlay :show="state.loading" rounded="sm">
            <div
                class="demo-spacing-0 d-flex justify-content-between m-1"
                dir="rtl"
            >
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="isActive = true"
                >
                    <feather-icon size="16" icon="FilterIcon" />
                    فیلتر
                </b-button>
                <b-button
                    @click="$refs['setting-modal'].toggle()"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="info"
                    v-if="$havePermission('CONFIG_SITE_OWNER')"
                >
                    <feather-icon size="16" icon="SettingsIcon" />
                    افزودن تنظیمات
                </b-button>
            </div>
            <search-and-filter :is-active.sync="isActive" :options="columns" />

            <b-modal
                ref="setting-modal"
                cancel-variant="outline-secondary"
                ok-title="ارسال"
                cancel-title="لغو"
                centered
                title="مدیریت تنظیمات"
                @ok.prevent="sendSetting"
                @hidden.prevent="closeModal"
                v-model="modal"
                size="lg"
            >
                <b-form>
                    <b-overlay :show="loading">
                        <b-form-group v-show="!editMode" label="کلید دسترسی">
                            <b-form-input
                                type="text"
                                placeholder="کلید"
                                v-model="setting.name"
                                :state="formValidationState.name"
                                trim
                            />
                            <b-form-invalid-feedback>
                                کلید باید حتما وارد شود.
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group label="نام نمایشی">
                            <b-form-input
                                type="text"
                                placeholder="نام"
                                v-model="setting.label"
                                :state="formValidationState.label"
                            />
                            <b-form-invalid-feedback>
                                نام باید حتما وارد شود.
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group label="داده">
                            <b-form-textarea
                                placeholder="مقدار داده"
                                v-model="setting.data"
                            />
                        </b-form-group>
                         <b-form-group label="داده انگلیسی">
                            <b-form-textarea
                                placeholder="مقدار داده"
                                v-model="setting.enData"
                            />
                        </b-form-group>
                    </b-overlay>
                </b-form>
            </b-modal>
            <div class="relative-over-x">
                <b-table
                    ref=""
                    :items="items"
                    :small="true"
                    responsive
                    :fields="columns"
                    primary-key="id"
                    show-empty
                    empty-text="اطلاعاتی یافت نشد"
                    @sort-dailyChanged="sort($event)"
                    no-local-sort
                    class="text-wrap"
                    style="min-height: 235px"
                >
                    <template #cell(label)="data">
                        <span dir="rtl">
                            {{ data.item.label }}
                        </span>
                    </template>

                    <template style="max-width:300px" #cell(data)="data">
                        <span dir="rtl">
                            {{ data.item.data }}
                        </span>
                    </template>

                    <template #cell(action)="data">
                        <template>
                            <b-dropdown
                                id="dropdown-offset"
                                variant="link"
                                no-caret
                                offset="80px"
                                :right="true"
                            >
                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item
                                    @click="editSetting(data.item.name)"
                                >
                                    <feather-icon icon="EditIcon" />
                                    <span class="align-middle ml-50"
                                        >ویرایش</span
                                    >
                                </b-dropdown-item>
                                <b-dropdown-item
                                    @click="deleteSetting(data.item.name)"
                                    v-if="$havePermission('CONFIG_SITE_OWNER')"
                                >
                                    <feather-icon icon="DeleteIcon" />
                                    <span class="align-middle ml-50"
                                    >حذف</span
                                    >
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </template>
                </b-table>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
import {
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BModal,
    BOverlay,
    BTable,
} from "bootstrap-vue"
import SearchAndFilter from "@/layouts/components/SearchAndFilter"

export default {
    components: {
        BCard,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BOverlay,
        BModal,
        SearchAndFilter,
        BFormInvalidFeedback,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
    },
    name: "Settings",
    data: () => ({
        test: 0,
        modal: false,
        pageLength: 3,
        dir: false,
        searchTerm: "",
        formValidationState: {
            name: null,
            label: null,
        },
        loading: false,
        editMode: false,
        currentPage: 1,
        perPage: 1,
        rows: 200,
        sortBy: "",
        isSortDirDesc: "",
        userData: [],
        isActive: false,
        perPageOptions: [5, 10, 20, 50, 100],
        items: [],
        columns: [
            {
                label: "نام",
                key: "label",
                sortable: false,
                searchType: "text",
            },
            {
                label: "داده",
                key: "data",
                sortable: false,
                // searchType: 'number'
            },
            {
                label: "جزییات",
                key: "action",
            },
        ],
    }),
    created() {
        this.emptySettingData()
        this.getData()
    },
    methods: {
        closeModal() {
            this.modal = false
            this.editMode = false
            this.emptySettingData()
        },
        async getData() {
            const setting = await this.$axios.get("/admins/site-config")

            this.items = setting.data
        },
        emptySettingData() {
            this.setting = {
                name: "",
                label: "",
                data: "",
                enData:""
            }
        },
        async sendSetting() {
            if(this.editMode) {
                this.updateSetting()
                return
            }
            this.createSetting()
        },
        checkValidation() {
            let valid = true
            if (this.setting.name.length === 0) {
                this.formValidationState.name = false
                valid = false
            } else this.formValidationState.name = true
            if (this.setting.label.length === 0) {
                this.formValidationState.label = false
                valid = false
            } else this.formValidationState.label = true
            return valid
        },
        editSetting(name) {
            this.setting = this.items.find((item) => item.name === name)
            this.editMode = true
            this.modal = true
        },
        async createSetting() {
            if (!this.checkValidation()) return
            this.loading = true
            await this.$axios.post("/admins/site-config", this.setting)
            this.loading = false
            this.modal = false
            this.emptySettingData()
            this.getData()
        },

        async updateSetting() {
            if (!this.checkValidation()) return
            this.loading = true
            await this.$axios.put("/admins/site-config", this.setting)
            this.loading = false
            this.modal = false
            this.emptySettingData()
            this.getData()
        },
        async deleteSetting(name) {
            await this.$axios.delete("/admins/site-config/?configName="+name)
            const settingIndex = this.items.findIndex(item => item.name === name)
            this.items.splice(settingIndex,1)
        },
    },
}
</script>

<style scoped></style>
